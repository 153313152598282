/**
 * @author Liu Yin
 * @date 2020/2/11
 * @Description: 弹窗混入
 */

export default {
  props: {
    // 弹框标题
    dialogTitle: {
      type: String,
      default() {
        return '新增'
      }
    },
    // 条目ID
    rowId: {
      type: [Number, Object],
      default() {
        return null
      }
    },
    // 条目数据
    rowData: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {
    // 关闭弹框
    handleClose() {
      this.$emit('close')
    },
    // 提交数据前校验表单
    validateForm(params) {
      const form = this.$refs.form
      if (form) {
        form.validate((valid) => {
          if (valid) {
            this.toggleLoading(true)
            this.submitForm(params)
          } else {
            this.$message.warning('请填写完整')
            return false
          }
        })
      } else {
        this.toggleLoading(true)
        this.submitForm(params)
      }
    },
    // 保存成功
    submitSuccess() {
      this.toggleLoading(false)
      this.$message.success('保存成功')
      // 通知父组件刷新列表数据
      this.$emit('submitSuccess')
      this.handleClose()
    },
    // 保存失败
    submitFailed(msg = '保存失败') {
      this.toggleLoading(false)
      this.$message.error(msg)
    }
  }
}
