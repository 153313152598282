<!--
   User: Liu Yin
   Date: 2020/2/27
   Description:
 -->
<template>
  <article style="height: 100%; display: flex; flex-direction: column">
    <user-header></user-header>
    <section>
      <article class="form-content">
        <span style="color: white; font-size: 13px">首 页 >> 用户注册</span>
        <hr>
        <el-form ref="form" class="el-form" :model="formData" :rules="registerRules" label-width="110px" label-position="left">
          <h1 style="color: #d00000; font-size: 18px; text-align: center">
            企业注册
          </h1>
          <div style="
              height: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              margin-top: 20px;
              position: relative;
            ">
            <hr style="
                height: 1px;
                background-color: #0054ff;
                width: 75%;
                border: initial;
              ">
            <div style="
                height: 3px;
                position: absolute;
                width: 200px;
                background-color: #0054ff;
                border-radius: 3px;
              "></div>
          </div>
          <div style="margin-right: 13%">
            <h1 style="color: #f22e0a; margin-top: 30px; margin-bottom: 20px">
              账户信息（Account Info）
            </h1>
            <el-row>
              <el-col :span="12">
                <el-form-item label="用户注册名：" prop="people.peopleLoginname" style="margin-left: 100px">
                  <el-tooltip class="item" effect="dark" content="注意：作为企业账户，一旦注册不可更改" placement="top-end">
                    <el-input v-model="formData.people.peopleLoginname" placeholder="请输入账号" maxlength="32">
                      <i slot="prefix" class="el-input__icon el-icon-user-solid" style="color: rgb(250, 59, 30); font-size: 18px"></i>
                    </el-input>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="登 录 密 码：" prop="people.peoplePassword" style="margin-left: 100px">
                  <el-input v-model="formData.people.peoplePassword" placeholder="请输入密码" type="password" maxlength="32">
                    <i slot="prefix" class="el-input__icon el-icon-lock" style="color: rgb(250, 59, 30); font-size: 18px"></i>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="公 司 名 称：" prop="enterName" style="margin-left: 100px">
                  <el-input v-model="formData.enterName" placeholder="请输入公司名称" maxlength="128">
                    <i slot="prefix" class="el-input__icon el-icon-office-building" style="color: rgb(250, 59, 30); font-size: 18px"></i>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="确 认 密 码：" prop="people.confirmPassword" style="margin-left: 100px">
                  <el-input v-model="formData.people.confirmPassword" placeholder="请输入确认密码" type="password" maxlength="32">
                    <i slot="prefix" class="el-input__icon el-icon-lock" style="color: rgb(250, 59, 30); font-size: 18px"></i>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <h1 style="color: #f22e0a; margin-top: 30px; margin-bottom: 20px">
              联系方式（Connect Info）
            </h1>
            <el-row>
              <el-col :span="12">
                <el-form-item label="经办人姓名：" prop="people.peopleName" style="margin-left: 100px">
                  <el-input v-model="formData.people.peopleName" placeholder="请输入姓名" maxlength="32">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所 属 部 门：" prop="people.deptName" style="margin-left: 100px">
                  <el-input v-model="formData.people.deptName" placeholder="请输入所属部门">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="手 机 号 码：" prop="people.peopleMobile" style="margin-left: 100px">
                  <el-input v-model="formData.people.peopleMobile" placeholder="请输入手机号" maxlength="32">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电 子 邮 箱：" prop="people.peopleMail" style="margin-left: 100px">
                  <el-input v-model="formData.people.peopleMail" placeholder="请输入电子邮箱" maxlength="128">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <h1 style="color: #f22e0a; margin-top: 30px; margin-bottom: 20px">
              公司信息（Enterprise Info）
            </h1>
            <el-row>
              <el-col :span="12">
                <el-form-item label="法 人 代 表" prop="legalPerson" style="margin-left: 100px">
                  <el-input v-model="formData.legalPerson" placeholder="请输入法人代表" maxlength="20">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所 属 行 业：" prop="personIndustrySelect" style="margin-left: 100px">
                  <el-cascader v-model="formData.personIndustrySelect" :options="industryOptions" :props="{ expandTrigger: 'hover', multiple: true }" :show-all-levels="false" mutiple placeholder="请输入所属行业" @change="handleChangeIndustry"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="成 立 日 期：" prop="foundTime" style="margin-left: 100px">
                  <el-date-picker v-model="formData.foundTime" style="width: 100%" type="date" value-format="yyyy-MM-dd" placeholder="请输入成立日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注 册 地 址：" prop="registAddress" style="margin-left: 100px">
                  <el-input v-model="formData.registAddress" placeholder="请输入注册地址" maxlength="128">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="企 业 类 型：" prop="enterType" style="margin-left: 100px">
                  <el-select v-model="formData.enterType" placeholder="请选择企业类型" value="" value-key="dictCode">
                    <el-option v-for="item in enterTypeList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注 册 资 金：" prop="enterCapital" style="margin-left: 100px">
                  <el-input v-model="formData.enterCapital" placeholder="请输入注册资金" maxlength="128">
                    <template slot="append">万元</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="公 司 地 址：" prop="enterAddress" style="margin-left: 100px">
                  <el-input v-model="formData.enterAddress" placeholder="请输入公司地址" maxlength="128">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="企业信用码：" prop="registno" style="margin-left: 100px">
                  <el-input v-model="formData.registno" placeholder="请输入企业信用码" maxlength="20">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="年 营 业 额：" prop="turnover" style="margin-left: 100px">
                  <el-input v-model="formData.turnover" placeholder="请输入年营业额" maxlength="128">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="企 业 描 述：" prop="enterDetails" style="margin-left: 100px">
                <el-input v-model="formData.enterDetails" type="textarea" :autosize="{ minRows: 3, maxRows: 8 }" placeholder="请输入企业描述" maxlength="1024">
                </el-input>
              </el-form-item>
            </el-row>
          </div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 20px;
            ">
            <p>
              <el-checkbox v-model="isChecked" style="cursor: pointer"></el-checkbox>
              <span style="color: #ff321c; margin-left: 10px">同意《注册协议》并按照相关要求执行</span>
            </p>
            <el-button size="small" round class="login-btn" style="width: 300px; margin-top: 20px" type="primary" @click="validateForm">立即注册</el-button>
          </div>
        </el-form>
      </article>

      <bottom-navigate></bottom-navigate>
    </section>
  </article>
</template>

<script>
import bottomNavigate from '../../../components/bottom-navigate'
import userHeader from '../../../components/header/userHeader'
import dialogMixin from '../../../utils/mixins/dialogMixin'
// eslint-disable-next-line no-unused-vars
import {
  validEmail,
  isPhone,
  noChineseCharacter
} from '../../../utils/validate'

export default {
  components: {
    bottomNavigate,
    userHeader
  },
  mixins: [dialogMixin],
  data() {
    return {
      formData: {
        people: {
          // 用户名
          peopleLoginname: '',
          // 密码
          peoplePassword: '',
          // 确认密码
          confirmPassword: '',
          // 经办人姓名
          peopleName: '',
          // 所属部门
          deptName: '',
          // 手机号码
          peopleMobile: '',
          // 电子邮箱
          peopleMail: ''
        },
        // 公司名称
        enterName: '',
        // 所属行业
        enterField: '',
        // 公司地址
        enterAddress: '',
        // 企业信用码
        registno: '',
        // 园区ID
        parkId: 1,
        personIndustrySelect: []
      },
      isChecked: false,
      enterTypeList: [],
      registerRules: {
        'people.peopleLoginname': [
          { required: true, trigger: 'blur', message: '请输入账号' },
          {
            validator: (rule, value, callback) => {
              if (noChineseCharacter(value)) {
                callback()
              } else {
                callback(new Error('账号不能有汉字'))
              }
            },
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const params = { userName: value }
              this.$get('/park/enter/checkLoginAccount', params, []).then(
                (result) => {
                  if (
                    (this.formData.enterId === undefined && result.data === 0) ||
                    (this.formData.enterId !== undefined && result.data === 1)
                  ) {
                    callback()
                  } else {
                    callback(new Error('账号已存在请重新输入！'))
                  }
                }
              )
            },
            trigger: 'blur'
          }
        ],
        'people.peoplePassword': [
          { required: true, trigger: 'blur', message: '请输入密码' }
        ],
        'people.confirmPassword': [
          { required: true, trigger: 'blur', message: '请输入确认密码' }
        ],
        'people.peopleName': [
          { required: true, trigger: 'blur', message: '请输入姓名' }
        ],
        'people.deptName': [
          { required: true, trigger: 'blur', message: '请选择部门' }
        ],
        'people.peopleMobile': [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (isPhone(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的联系方式'))
              }
            },
            trigger: 'blur'
          }
        ],
        'people.peopleMail': [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (validEmail(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的邮箱'))
              }
            },
            trigger: 'blur'
          }
        ],
        enterName: [
          { required: true, trigger: 'blur', message: '请输入公司名称' }
          // {
          //   validator: (rule, value, callback) => {
          //     const params = { enterName: value }
          //     this.$get('/park/enter/checkLoginAccount', params, []).then(
          //       (result) => {
          //         if (
          //           (this.formData.enterId === undefined && result.data === 0) ||
          //           (this.formData.enterId !== undefined && result.data === 1)
          //         ) {
          //           callback()
          //         } else {
          //           callback(new Error('公司名称已存在请重新输入！'))
          //         }
          //       }
          //     )
          //   },
          //   trigger: 'blur'
          // }
        ],
        enterField: [
          { required: true, trigger: 'blur', message: '请选择行业' }
        ],
        enterAddress: [
          { required: true, trigger: 'blur', message: '请输入地址' }
        ],
        enterCapital: [{ pattern: /^[1-9]\d*$/, message: '请填写正整数' }],
        turnover: [{ pattern: /^[1-9]\d*$/, message: '请填写正整数' }]
      },
      departmentList: [],
      industryOptions: []
    }
  },
  mounted() {
    this.getDictObj()
  },
  methods: {
    handleRegister() {
      this.$router.push('/')
    },
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'enter_industry,enter_type'
      })
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const dictObj = result.data
            this.enterTypeList = result.data.enter_type
            const industryList = dictObj.enter_industry
            this.industryOptions = this.converseData(industryList)
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    // Array转化成Parent->children
    converseData(originList) {
      if (!originList) return []
      const list = JSON.parse(JSON.stringify(originList))
      const result = []
      const map = {}
      list.forEach((item) => {
        item.label = item.dictValue
        item.value = item.dictCode
        map[item.dictId] = item
      })
      list.forEach((item) => {
        const parent = map[item.parentId]
        if (parent) {
          (parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      return result
    },
    handleChangeIndustry(val) {
      if (this.formData.personIndustrySelect.length > 3) {
        this.$message.warning('最多选择3个行业')
        this.formData.personIndustrySelect =
          this.formData.personIndustrySelect.splice(0, 3)
      }
    },
    submitForm() {
      this.toggleLoading(false)
      if (!this.isChecked) {
        this.$message.warning('请勾选同意注册协议')
        return
      }
      if (
        this.formData.people.peoplePassword !==
        this.formData.people.confirmPassword
      ) {
        this.$message.warning('密码不一致,请重新输入')
        return
      }

      this.$get(this.urls.checkUsername, {
        username: this.formData.people.peopleLoginname
      }).then((result) => {
        if (result.code === 'SUCCESS') {
          if (result.data === 1) {
            this.$message.error('用户名已存在')
            return
          }
          this.saveData()
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    saveData() {
      this.toggleLoading(true)
      // 格式化行业
      let personIndustry = ''
      this.formData.personIndustrySelect.forEach((item, index) => {
        if (index === this.formData.personIndustrySelect.length - 1) {
          personIndustry = personIndustry.concat(item[1])
        } else {
          personIndustry = personIndustry.concat(item[1] + ',')
        }
      })
      this.formData.enterField = personIndustry
      this.formData.enterFrom = 1
      this.$post(this.urls.register, this.formData)
        .then((result) => {
          this.toggleLoading(false)
          if (result.code === 'SUCCESS') {
            this.$message.success('注册成功')
            this.$store.dispatch('app/setLoginType', '2')
            this.$router.go(-1)
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-content {
  background-image: url("../../../assets/images/register/register-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding: 15px 20%;
  box-sizing: border-box;
}

.login-content {
  height: 100%;
  background-image: url("../../../assets/images/login/login-bg.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .bottom-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 800px;
    margin: 10px auto;
  }
  .login-form-parent {
    width: 400px;
    height: 100%;
    position: absolute;
    right: 10%;
    display: flex;
    align-items: center;
    .login-form {
      width: 400px;
      height: 300px;
      background: white;
      opacity: 0.6;
      border-radius: 10px;
    }
  }
}
.el-form {
  background: linear-gradient(
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.6) 28%,
    rgba(255, 255, 255, 0.4) 59%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 20px 5%;
}
::v-deep .el-input__inner {
  height: 30px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 13px;
}
</style>
